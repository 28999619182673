
import { get, patch, post } from "@/api/axios";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  IEmployee,
  IExaGroup,
  IExaQAItem,
  IExaSku,
  IExaTask
} from "@/state/models";
import EntityPropertiesEdit from "@/components/EntityPropertiesEdit.vue";
import DragAndDrop from "@/components/DragAndDrop.vue";
import StatusNew from "@/components/StatusNew.vue";
import Security from "@/components/Security.vue";
import TableRootEntities from "@/components/TableRootEntities.vue";
import UserSelector from "@/components/UserSelector.vue";
import SingleUserSelector from "@/components/SingleUserSelect.vue";

@Component({
  components: {
    EntityPropertiesEdit,
    DragAndDrop,
    Security,
    TableRootEntities,
    UserSelector,
    SingleUserSelector,
    StatusNew
  }
})
export default class ExaminationView extends Vue {
  vShow(vShow: any) {
    throw new Error("Method not implemented.");
  }
  headers: Array<any> = [
    {
      text: "Вопрос",
      sortable: false,
      align: "start",
      value: "question"
    }
  ];
  tab = "tab-selected";

  statusEnumeration: any[] = [
    {
      name: "created",
      display: "Новый",
      color: "dark blue blue--text text--darken-1"
    },
    {
      name: "published",
      display: "Опубликован",
      color: "dark green green--text text--darken-1"
    },
    {
      name: "archived",
      display: "В архиве",
      color: "dark grey grey--text text--darken-1"
    }
  ];

  fields: Array<any> = [
    {
      icon: "mdi-file-document",
      caption: "Имя",
      placeholder: "Имя не указано",
      name: "name",
      type: "string",
      hint: "hint",
      message: "Введите имя"
    },
    {
      icon: "mdi-file-document",
      caption: "Формула оценки",
      placeholder: "Фомула оценки не указана",
      name: "evaluation",
      type: "string",
      hint: "hint",
      message: "Введите формулу оценки в формате JSON"
      }
  ];

  public question_selected: IExaQAItem[] = [];
  public question_all: IExaQAItem[] = [];
  public searchText: string = "";

  public get taskID(): string {
    return this.$route.params.id;
  }

  public entity: IExaTask | null = null;

  is_loaded: boolean = false;

  created() {
    get(`/api/@examination/exa/task/${this.taskID}`).then(async x => {
      this.entity = x.data;
      this.prev_status = this.entity!.status;
      console.log("Success fetch entity", x.data);

      await get(`/api/@examination/exa/question`).then(x => {
        this.question_all = x.data;
        console.log("Success fetch all questions", x.data);
      });

      await get(`/api/@examination/exa/task/${this.taskID}/question`).then(
        x => {
          this.question_selected = x.data;

          // wtf???
          this.$nextTick(() => {
            this.question_selected = x.data;
            console.log("Success fetch selected questions", x.data);
          });
        }
      );

      setTimeout(() => (this.is_loaded = true), 400);
    });
  }

  private prev_status = "";

  statusUpdate() {
    if (this.prev_status == "published" && this.entity!.status == "created") {
      this.entity!.status = "published";
      return;
    }

    if (this.prev_status == "archived" && this.entity!.status == "created") {
      this.entity!.status = "archived";
      return;
    }

    if (this.prev_status == "archived" && this.entity!.status == "published") {
      this.entity!.status = "archived";
      return;
    }

    if (this.entity!.status == "published") {
      post(`/api/@examination/exa/task/${this.taskID}/publish`, {}).then(x => {
        console.log("Success published");
        this.prev_status = this.entity!.status;
      });
    }
  }

//   customFilter (item: any, queryText: any, itemText: any) {
//     const hasValue = val => val != null ? val : ''

//     const text = hasValue(itemText)
//     const query = hasValue(queryText)

//     return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
//   }

  @Watch("question_selected")
  saveQuestions() {
    if (!this.is_loaded) return;
    patch(
      `/api/@examination/exa/task/${this.taskID}/question`,
      this.question_selected.map(x => x._id)
    ).then(x => {
      console.log("Success update questions", x.data);
      this.entity = x.data;
    });
  }

  saveEntity() {
    patch(`/api/@examination/exa/task/${this.taskID}`, this.entity).then(x => {
      console.log("Success update entity", x.data);
      this.entity!.name = x.data.name;
      this.entity!.evaluation = x.data.evaluation;
    });
  }
}
