
import { Component, Prop, VModel, Vue } from "vue-property-decorator";


@Component
export default class StatusNew extends Vue {
    @Prop({ default: () => [], required: true, type: Array })
    public statusEnumeration!: Array<any>;

    @VModel({ required: true, type: String })
    public model!: string;

    @Prop({ required: false, type: Function, default: () => { } })
    public onUpdate!: Function;

    @Prop({ required: false, type: Boolean, default: false })
    public readonly!: boolean;



    get statusDisplay() {
        const d = this.statusEnumeration.find(x => x.name == this.model);
        return d ? d.display : "Не определен";
    }
    get statusColor() {
        const d = this.statusEnumeration.find(x => x.name == this.model);
        return d ? d.color : "gray";
    }

    updateStatus() {
        console.log(`updateStatus(${this.model})`);
        this.onUpdate();
        this.$emit("onUpdate");
    }
};
