
import { get } from "@/api/axios";
import { IEmployee } from "@/state/models";
import { Component, VModel, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class UserSelector extends Vue {

    @Prop({ required: false, default: "Сотрудники", type: String })
    public title!: string;

    @Prop({ required: false, default: false, type: Boolean })
    public loading!: boolean;

    @Prop({ required: false, default: (x: IEmployee) => true, type: Function })
    public filter!: (x: IEmployee) => boolean;

    @Prop({ required: false, default: `/employees`, type: String })
    public fetchEndpoint!: string;

    @Prop({ required: false, default: (x: IEmployee[]) => { console.warn("default onUpdate called") }, type: Function })
    public onUpdate!: (x: IEmployee[]) => void;

    @VModel({ required: false, default: () => [], type: Array  })
    public selectedItems!: IEmployee[];

    public users: IEmployee[] = [];

    created() {
        get(this.fetchEndpoint)
            .then(x => {
                this.users = x.data;
            });
    }
}
